import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Box, Text, chakra } from '@chakra-ui/react';
import { SubHeading } from '../styles/HeadingStyles';
import BlogByLine from './BlogByLine';

const cleanBlogContent = (html: string): string => {
  const cleanString = html.replace(/(<([^>]+)>)/gi, '');
  return `${cleanString}`;
};

type Props = {
  data: {
    content: string;
    title: string;
    author: {
      name: string;
    };
    meta: {
      createdAt: string;
    };
    date: string;
    slug: string;
    featuredMedia: {
      gatsbyImageData: IGatsbyImageData;
      alt: string;
    };
    defaultImage: {};
  };
  defaultImage?: {
    featuredMedia: {
      alt?: string;
      gatsbyImageData: {};
    };
  };
};

const BlogContent = chakra('p', {
  baseStyle: {
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    flexGrow: '1',
  },
});

/** A component that displays the contents of each blog. Gets blog contents from DatoCMS */
const BlogExcerpt = ({ data: node, defaultImage }: Props): JSX.Element => {
  const { content } = node;
  if (node) {
    return (
      <Box
        flex={['1 1 350px', '0 1 350px']}
        marginLeft={['0', '8']}
        marginBottom={['16', '32']}
      >
        <Link
          to={`/blog/${node.slug}`}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            textDecoration: 'none',
            color: '#333',
          }}
        >
          <Box mb={['4']}>
            {node.featuredMedia?.gatsbyImageData ? (
              <GatsbyImage
                image={node.featuredMedia.gatsbyImageData}
                alt={node.featuredMedia.alt}
                style={{ height: '197px' }}
              />
            ) : (
              <GatsbyImage image={defaultImage} alt={node.title} />
            )}
          </Box>

          <SubHeading as="h2" mb={['5', '7']}>
            {node.title}
          </SubHeading>
          <BlogContent
            mb={['5', '8']}
            dangerouslySetInnerHTML={{
              __html: cleanBlogContent(content),
            }}
          />
          <BlogByLine
            date={node.meta.createdAt}
            name={node.author ? node.author.name : 'SpotCast Team'}
            fontSize="0"
          />
        </Link>
      </Box>
    );
  }
  return <div />;
};

export default BlogExcerpt;
