import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import Section from '../components/ui/Section';
import Header from '../components/ui/Header';
import Containers from '../components/ui/Containers';
import BlogExcerpt from '../components/ui/BlogExcerpt';

// TODO Add in SEO

/** This template functions as our blog index */
const BlogListTemplate = ({ data }) => {
  const [currentCategory] = useState('all');

  const showCategory = (category: string, data, defaultImage: string) => {
    if (category === 'all') {
      return data.map(({ node }) => (
        <BlogExcerpt key={node.id} data={node} defaultImage={defaultImage} />
      ));
    }
    const catMatch = data
      .filter(({ node }) => node.categories.length)
      .filter(({ node }) =>
        node.categories.map((cat) => cat.slug).some((slug) => slug === category)
      )
      .map(({ node }) => node);
    return catMatch
      .filter((node) => node !== undefined)
      .map((node) => (
        <BlogExcerpt key={node.id} data={node} defaultImage={defaultImage} />
      ));
  };

  return (
    <>
      <Helmet>
        <title>Blog by SpotCast</title>
        <meta
          name="description"
          content="Looking for info on how to create the best video instructions? Follow our blog for up to date advice on keeping your people safe."
        />
      </Helmet>
      <Header title="Blog" image={false} />

      <Section mb={[4, 7]}>
        <Containers maxWidth="maxWidth">
          <Flex
            flexWrap="wrap"
            width={['100%', 'calc(100% + 36px)']}
            mx={[0, '-32px']}
          >
            {showCategory(
              currentCategory,
              data.allDatoCmsArticle.edges,
              data.file.childImageSharp.gatsbyImageData
            )}
          </Flex>
        </Containers>
      </Section>
    </>
  );
};

export default BlogListTemplate;

export const blogListQuery = graphql`
  query blogListQuery {
    allDatoCmsCategory(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
    allDatoCmsArticle(
      filter: { locale: { eq: "en" }, noIndex: { ne: true } }
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      edges {
        node {
          title
          id
          meta {
            createdAt
          }
          content
          categories {
            name
            slug
          }
          slug
          author {
            name
          }
          featuredMedia {
            alt
            gatsbyImageData(
              width: 600
              height: 400
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                crop: "faces"
                fit: "cover"
                dpr: 2
                w: "700"
              }
            )
          }
        }
      }
    }
    file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
  }
`;
